import React from 'react'
import Features from '../components/homePage/Features'
import Header from '../components/homePage/Header'
import Products from '../components/homePage/Products'
import "./../css/homePage.css"
export default function Home() {
    window.addEventListener('scroll', () => {
        const navbar = document.getElementById("navbar");
        if (window.scrollY < 100) {
            navbar.classList.remove('nav-no-background')
        } else {
            navbar.classList.add('nav-no-background')
        }
    });

    return (
        <div>
            <Header />
            <Features />
            <Products />
        </div>
    )
}
