import { MainContext } from './../App.js';
import { useContext, useEffect } from 'react';
import { useParams, useRoutes } from 'react-router-dom';
import config from './../config.js'
// routes
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const params = useParams()
    const { activeProjectState, projectsState, rafflesState } = useContext(MainContext)
    const [,setActiveProject] = activeProjectState
    const [, setRaffles] = rafflesState
    const [projects] = projectsState
    useEffect(() => {
        async function setProjectData() {

        if (params.id) {
            const newActiveProject = projects.find(x => x.id === params.id)
            if (newActiveProject) {
                setActiveProject(newActiveProject)
                const rafflesInfo = await fetch(`${config.apiURL}/get-raffles`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        projectId: newActiveProject.id
                    })
                })
                const rafflesInfoJson = await rafflesInfo.json()
                console.log(rafflesInfoJson)
                if (rafflesInfoJson.error) {
                    setRaffles({status: rafflesInfoJson.error})
                } else {
                    rafflesInfoJson.raffles = rafflesInfoJson.raffles.sort((a, b) => b.createdAt - a.createdAt)
                    setRaffles(rafflesInfoJson.raffles)
                }
            }
            else {
                setActiveProject({})
            }
        } else {
            setActiveProject({})
        }
    } setProjectData()
    }, [params.id])
    
    return useRoutes([MainRoutes]);
}
