import React from 'react'
import LoggingIcon from './../../assets/img/homePage/loggingIcon.png'
import CustomizableIcon from './../../assets/img/homePage/customizableIcon.png'

export default function Features() {
    return (
        <div className='features' id='features'>
            <div className='features-title'>
                Features
            </div>
            <div className='features-flex'>
                <div className='feature-box'>
                    <div className='feature-title' >
                        {/* <img src={LoggingIcon} className="feature-img" alt="feature-1" /> */}
                        <span>
                            FAST SERVICE
                        </span>
                    </div>
                    <div className='feature-description'>
                        Our team don't make you wait for hours for support.
                    </div>
                </div>

                <div className='feature-box'>
                    <div className='feature-title' >
                        {/* <img src={CustomizableIcon} className="feature-img" alt="feature-2" /> */}
                        <span>
                            FULLY CUSTOMIZABLE
                        </span>
                    </div>
                    <div className='feature-description'>
                        You can customize the design as well as the way any tool works!
                    </div>

                </div>

                <div className='feature-box'>
                    <div className='feature-title' >
                        {/* <img src={LoggingIcon} className="feature-img" alt="feature-3" /> */}
                        <span>
                            EASY TO NAVIGATE
                        </span>
                    </div>
                    <div className='feature-description'>
                    Our platform is very convenient and easy to navigate through
                    </div>

                </div>

            </div>
        </div>
    )
}
