import React from 'react'
import stakingProduct from './../../assets/img/homePage/stakingProduct.png'
import holderVerificationProduct from './../../assets/img/homePage/holderVerificationProduct.png'
import raffleProduct from './../../assets/img/homePage/raffleProduct.png'

export default function Products() {
    return (
        <div className='products' id='products'>
            <div className='products-title'>
                Products
            </div>

            <div className='products-flex'>
                <div className='product-box'>
                    <div className='product-title' >
                        <img src={stakingProduct} className="product-img" alt="product-1" />
                        <span>
                            STAKING
                        </span>
                    </div>
                    <div className='product-description'>
                    Our staking website includes multiple options and multiple tokens for rewards. Every NFT can have different reward on the basis of trait/rank or anything else.  
                    </div>
                </div>

                <div className='product-box'>
                    <div className='product-title' >
                        <img src={holderVerificationProduct} className="product-img" alt="product-2" />
                        <span>
                            HOLDER VERIFICATION
                        </span>
                    </div>
                    <div className='product-description'>
                    Verify holders and role them on discord.
                    <div>COMING SOON</div>
                    </div>

                </div>

                <div className='product-box'>
                    <div className='product-title' >
                        <img src={raffleProduct} className="product-img" alt="product-3" />
                        <span>
                            RAFFLE
                        </span>
                    </div>
                    <div className='product-description'>
                        You can setup a good looking raffle site for your project which includes multiple entry options for ticket price!
                    </div>

                </div>

            </div>
        </div>

    )
}
