import React, { createContext, useContext, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Navbar from '../../components/utility/Navbar'
import SolanaWalletProvider from '../../components/utility/WalletConnect/SolanaWalletProvider'
import Dashboard from '../Dashboard'
import Home from '../Home'
import { Config } from '../../config'
import { showAlert, sleep, updateStateObject } from '../../javascript/utility/utility'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import Alert from '../../components/utility/Alert'
import { CircularProgress, FormControlLabel, Switch } from '@mui/material'
import config from '../../components/dashboard/config'
import bse58 from 'bs58'
import { PublicKey, Transaction, TransactionInstruction } from '@solana/web3.js'

export const Context = createContext({
    activeProjectState: undefined,
    projectsState: undefined,
    alertState: undefined,
    displaySignModalState:undefined
});

export default function HomeDashboardRouter() {
    const [projects, setProjects] = useState()
    const [activeProject, setActiveProject] = useState()
    const [alert, setAlert] = useState([])
    const [displaySignModal, setDisplaySignModal] = useState(false)
    const { publicKey } = useWallet()
    useEffect(() => {
        async function fetchProjects() {
            if (publicKey) {
                try {
                    const projects = await fetch(`${Config.apiURL}/get-projects`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            walletAddress: publicKey
                        })
                    })
                    const projectsJson = await projects.json()
                    if (projectsJson.error) {
                        showAlert(setAlert, projectsJson.error)
                        return
                    }
                    setProjects(projectsJson.projects)
                } catch {
                    showAlert(setAlert, 'An unknown error occured')
                    return
                }
            }
        }
        fetchProjects()
    }, [publicKey])

    return (
        <Context.Provider value={{
            activeProjectState: [activeProject, setActiveProject],
            projectsState: [projects, setProjects],
            alertState: [alert, setAlert],
            displaySignModalState: [displaySignModal, setDisplaySignModal]
        }}>
            <Alert />
            <SignModal displayModal={displaySignModal} setDisplayModal={setDisplaySignModal} />
            <Routes>
                <Route path='/' element={(<><Navbar /><Home /></>)} />
                <Route path='/dashboard/*' element={<Dashboard />} />
            </Routes>
        </Context.Provider>
    )
}


export const SignModal = ({ displayModal, setDisplayModal }) => {
    const { signMessage, publicKey, sendTransaction } = useWallet()
    const {connection} = useConnection()
    const { alertState, activeProjectState } = useContext(Context)
    const [project] = activeProjectState
    const [, setAlert] = alertState
    const [ledger, setLedger] = useState(false)
    const [disableSignBtn, setDisableSignBtn] = useState(false)
    if (!displayModal) {
        return (<></>)
    } else {
        return (
            <>
                <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
                    <div className='modal-bg'></div>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div style={{ justifyContent: 'center', textAlign: 'center', color: 'white', fontFamily: 'Montserrat', fontSize: '1.5rem', fontWeight: '600', padding: '2rem' }}>Sign Message
                                <div style={{ fontSize: '1rem', marginTop: '1.5rem' }}>You need to sign message to verify ownership of wallet</div>
                                <div style={{ margin: '0.8rem' }}>
                                    <FormControlLabel style={{
                                        padding: '0.1rem 0.8rem 0.1rem 0.8rem',
                                        background: '#39394a52',
                                        borderRadius: '200px'
                                    }} control={<Switch checked={ledger} onChange={() => setLedger(!ledger)} />} labelPlacement="start" label="Using Ledger?: " />
                                </div>
                                <button className='font-montserrat utility-btns__purple-btn' style={{ margin: '1rem' }} onClick={() => verifyWallet(signMessage, setAlert, setDisplayModal, publicKey, project.id, ledger, sendTransaction, connection, setDisableSignBtn)} disabled={disableSignBtn}>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    {disableSignBtn && (<CircularProgress size={'1.3rem'} style={{margin: '0px 0.5rem 0px 0px'}} />)}
                                    <div>Sign Message</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export const verifyWallet = async (signMessage, setAlert, setDisplayModal, walletAddress, projectId, ledger, sendTransaction, connection, setDisableSignBtn) => {
    try {
        setDisableSignBtn(true)
        const nonceReq = await fetch(`${config.apiURL}/get-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId
            })
        })
        const nonce = await nonceReq.json()
        if (nonce.error) return showAlert(setAlert, nonce.error)
        else {
            let signature;
            if (!ledger) {
                signature = await signMessage(new TextEncoder().encode(`${nonce.nonce}`))
                signature = bse58.encode(signature)
            } else {
                let tx = new Transaction();
                await tx.add(
                    new TransactionInstruction({
                        keys: [{ pubkey: walletAddress, isSigner: true, isWritable: true }],
                        data: Buffer.from(nonce.nonce, "utf-8"),
                        programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
                    })
                )
                const blockHash = await connection.getLatestBlockhash();
                tx.feePayer = walletAddress;
                tx.recentBlockhash = blockHash.blockhash;

                signature = await sendTransaction(tx, connection)
                await connection.confirmTransaction({
                    blockhash: blockHash.blockhash,
                    lastValidBlockHeight: blockHash.lastValidBlockHeight,
                    signature
                });
                let checked = false
                for (var i = 0; i <= 10; i++) {
                    const response = await checkVerifiedWallet(walletAddress, projectId, nonce.id, signature)
                    if (response.nonce) {
                        await sleep(10000)
                        continue
                    } if (response.error) {
                        await sleep(10000)
                        continue
                    } else {
                        checked = true
                        break;
                    }
                }
                if (!checked) {
                    setDisableSignBtn(false)
                    return showAlert(setAlert, `Couldn't verify your wallet`)
                }
            }
            if (!signature) {
                setDisableSignBtn(false)
                return showAlert(setAlert, `An Error occured while signing`)
            }
            else {
                localStorage.setItem('nonceSignature', signature)
                localStorage.setItem('nonceId', nonce.id)
                localStorage.setItem('nonceExpiry', Date.now() + 7 * 24 * 60 * 60 * 1000)
                setDisplayModal(false)
                setDisableSignBtn(false)
                showAlert(setAlert, `Successfully signed in`, 'success')
            }
        }
    } catch (err) {
        setDisableSignBtn(false)
        console.log(err)
        showAlert(setAlert, `An unknown error occured.`)
    }
}


export const checkVerifiedWallet = async (walletAddress, projectId, nonceId, signature) => {
    try {
        const nonceReq = await fetch(`${config.apiURL}/verify-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId,
                nonceId: nonceId,
                signature: signature
            })
        })
        const nonce = await nonceReq.json()
        return nonce
    } catch {
        return { error: 'An unknown error occured' }
    }
}
