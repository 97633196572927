import { lazy } from 'react';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from './../ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('./../views/dashboard/Default')));

// utilities routing
const Raffle = Loadable(lazy(() => import('./../views/utilities/Raffle')));
// const UtilsColor = Loadable(lazy(() => import('./../views/utilities/Color')));
const ComingSoon = Loadable(lazy(() => import('./../views/utilities/ComingSoon')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/project',
            element: <DashboardDefault />
        },
        {
            path: '/raffle',
            element: <Raffle />
        },
        {
            path: 'staking',
            element: <ComingSoon />
        },
        {
            path: 'casino',
            element: <ComingSoon />
        }
    ]
};

export default MainRoutes;
