import { useWallet } from '@solana/wallet-adapter-react'
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import React from 'react'

export default function ConnectButton(props) {
    const { publicKey } = useWallet()
    if (!publicKey) {
        return (
            <WalletMultiButton className='button-orange-border button-cyan-border' style={{ fontSize: "1.3rem" }}></WalletMultiButton>
        )
    } else {
        return (
            <WalletDisconnectButton className='button-orange-border button-cyan-border' style={{ fontSize: "1.3rem" }} />
        )
    }

}
