// assets
import { IconConfetti, IconPalette, IconCash, IconPlayCard } from '@tabler/icons';

// constant
const icons = {
    IconConfetti,
    IconPalette,
    IconCash,
    IconPlayCard
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'raffles',
            title: 'Raffle',
            type: 'item',
            url: '/raffle',
            icon: icons.IconConfetti,
            breadcrumbs: false
        },
        {
            id: 'staking',
            title: 'Staking',
            type: 'item',
            url: '/staking',
            icon: icons.IconCash,
            breadcrumbs: false
        },
        {
            id: 'casino',
            title: 'Casino',
            type: 'item',
            url: '/casino',
            icon: icons.IconPlayCard,
            breadcrumbs: false
        }
        // {
        //     id: 'icons',
        //     title: 'Icons',
        //     type: 'collapse',
        //     icon: icons.IconWindmill,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Tabler Icons',
        //             type: 'item',
        //             url: '/icons/tabler-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Material Icons',
        //             type: 'item',
        //             url: '/icons/material-icons',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default utilities;
