const Logo = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexFlow: 'row wrap'
        }}>
            <img src="https://media.discordapp.net/attachments/988018832016801793/1063447229945360424/favicon.png" alt="Berry" width="50" />
            <div style={{
                fontSize: '1.2rem',
                margin: '1rem',
                fontWeight: '500',
                textTransform: 'uppercase',
                fontFamily: 'Montserrat'
            }}>ETA Labs</div>
        </div>
    );
};

export default Logo;
