import React, { useContext } from 'react'
import { Context } from '../../pages/routers/HomeDashboardRouter'

export default function Alert() {
    const {alertState} = useContext(Context)
    const [alert] = alertState
    if (!alert || !alert.length) return (<></>)
    return (
        <div className='alert'>
            <div className='alert-container'>
                {alert.map((y, i) => {
                    let bgColor = 'red'
                    if (y.type === 'success') bgColor = 'green'
                    return (
                        <div key={i} style={{ backgroundColor: bgColor }} className='font-signika alert-info'>
                            {y.message}
                            {/* <span style={{cursor: "pointer", fontWeight: '800'}}><i className='bx bx-x'></i></span> */}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}