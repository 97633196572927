import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import './css/utility.css'
import './css/dashboard.css'
import App from './App.js';
import { store } from './store';

// style + assets
import './assets/scss/style.scss';

import config from './config';
import SolanaWalletProvider from './utils/walletAdapter/SolanaWalletProvider';
export default function Dashboard() {
    return (
        // <SolanaWalletProvider>
            <Provider store={store}>
                    <App />
            </Provider>
        // </SolanaWalletProvider>
    )
}