import React, { useMemo } from 'react'
import {
    AlphaWalletAdapter,
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SaifuWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { Config } from '../../../config';

require('@solana/wallet-adapter-react-ui/styles.css');

export default function SolanaWalletProvider({children}) {
    const network = Config.SolanaCluster
    const endpoint = useMemo(() => (network), [network]);

    const wallets = useMemo(() => [
        new PhantomWalletAdapter(),
        new SlopeWalletAdapter(),
        new GlowWalletAdapter(),
        new AlphaWalletAdapter(),
        new SaifuWalletAdapter(),
        new SolletExtensionWalletAdapter(),
        new SolletWalletAdapter(),
        new SolflareWalletAdapter({ network }),
        new TorusWalletAdapter()
    ], [network])
    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}
