import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { closeMobileNavbar, toggleMobileNavbar } from '../../javascript/utility/navbar'
import Logo from './../../assets/img/utility/logo.png'
import Ethereum from './../../assets/img/utility/WalletConnect/Ethereum.png'
import Solana from './../../assets/img/utility/WalletConnect/Solana.png'
import ConnectWalletButton from './WalletConnect/ConnectWalletButton'

export default function Navbar() {
    return (
        <>
            <div id='mobile-navbar' style={{ display: "flex", flexFlow: "row wrap", justifyContent: "center" }}>
                <div className='navbar-content' style={{ position: "fixed", top: "5vh", width: "80vw" }}>
                    <div style={{paddingTop: "20px", paddingBottom: "20px"}}>
                        <img src={Logo} alt="Logo" style={{ width: "4rem"}} />
                    </div>
                    {/* <label htmlFor='navbar-checkbox-id' onClick={toggleMobileNavbar}><span id='hamburger-icon'>ⓧ</span></label> */}
                </div>
                <ConnectWalletButton/>
{/* 
                <div style={{ display: "block", width: "80vw" }}>
                    <div className='navbar-links-mobile'>
                        <HashLink to="/#header" style={{ textDecoration: "none" }} onClick={closeMobileNavbar}>
                            <div className='navbar-link-btn-mobile'>
                                Home
                            </div>
                        </HashLink>
                        <HashLink to="/#features" style={{ textDecoration: "none" }} onClick={closeMobileNavbar}>
                            <div className='navbar-link-btn-mobile'>
                                Features
                            </div>
                        </HashLink>
                        <HashLink to="/#products" style={{ textDecoration: "none" }} onClick={closeMobileNavbar}>
                            <div className='navbar-link-btn-mobile'>
                                Product
                            </div>
                        </HashLink>
                        <Link to="/dashboard" style={{ textDecoration: "none" }} onClick={closeMobileNavbar}>
                            <div className='navbar-link-btn-mobile'>
                                Dashboard
                            </div>
                        </Link>
                        <div className='navbar-link-btn-mobile' onClick={closeMobileNavbar}>
                            <div className='navbar-connect-mobile'>
                                <ConnectWalletButton/>
                            </div>
                        </div>


                    </div>

                </div> */}
            </div>


            <div className='navbar' id='navbar'>
                <div className='navbar-content'>
                    <div style={{paddingTop: "20px", paddingBottom: "20px"}}>
                        <img src={Logo} alt="Logo" style={{ width: "4rem" }} />
                    </div>
                    <div>
                    {/* <ConnectWalletButton/> */}

                        {/* <input type={"checkbox"} id="navbar-checkbox-id" style={{ display: "none" }} /> */}
                        {/* <label htmlFor='navbar-checkbox-id' onClick={toggleMobileNavbar}><span id='hamburger-icon'>☰</span></label> */}
                    </div>

                    <div className='navbar-links'>
                        <HashLink to="/#header" style={{ textDecoration: "none" }}>
                            <div className={`navbar-link-btn`} id='navbar-link-1' >
                                Home
                            </div>
                        </HashLink>

                        <HashLink to="/#features" style={{ textDecoration: "none" }}>
                            <div className={`navbar-link-btn`} id='navbar-link-2'>
                                Features
                            </div>
                        </HashLink>

                        <HashLink to="/#products" style={{ textDecoration: "none" }}>

                            <div className={`navbar-link-btn`} id='navbar-link-3'>
                                Product
                            </div>
                        </HashLink>
                        <Link to={'/dashboard'} style={{ textDecoration: "none" }}>
                            <div className={`navbar-link-btn`} id='navbar-link-4'>
                                Dashboard
                            </div>
                        </Link>
                    </div>

                    <div className='navbar-connect' style={{display: 'block'}}>
                        <ConnectWalletButton/>
                    </div>

                </div>
            </div>
        </>
    )
}

