// assets
import { IconSettings } from '@tabler/icons';

// constant
const icons = { IconSettings };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'project',
    title: 'Project',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Project Setting',
            type: 'item',
            url: '/project',
            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
