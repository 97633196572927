import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';

// routing
import DashRoutes from './routes';
import config from './config.js'
// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import { createContext, useContext, useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { AppBar, Toolbar, CssBaseline, CircularProgress } from '@mui/material';
import LogoSection from './layout/MainLayout/LogoSection/index.js';
import { Box, styled, useTheme } from '@mui/system';
import ConnectButton from './utils/walletAdapter/ConnectWalletButton';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import { Context } from '../../pages/routers/HomeDashboardRouter';
import { showAlert } from '../../javascript/utility/utility';

export const MainContext = createContext({
    activeProjectState: undefined,
    projectsState: undefined,
    alertState: undefined,
    rafflesState: undefined,
    displaySignModalState: undefined

})
const App = () => {

    const [raffles, setRaffles] = useState()
    const { publicKey } = useWallet()
    const { alertState, projectsState, activeProjectState, displaySignModalState} = useContext(Context)
    const [projects, setProjects] = projectsState
    const [activeProject, setActiveProject] = activeProjectState
    const [alert, setAlert] = alertState

    useEffect(() => {
        async function fetchProjects() {
            if (publicKey) {
                try {
                    const projects = await fetch(`${config.apiURL}/get-projects`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            walletAddress: publicKey
                        })
                    })
                    const projectsJson = await projects.json()
                    if (projectsJson.error) {
                        showAlert(setAlert, projectsJson.error)
                        return
                    }
                    setProjects(projectsJson.projects)
                } catch {
                    showAlert(setAlert, 'An unknown error occured')
                    return
                }
            }
        }
        fetchProjects()
    }, [publicKey])


    const customization = useSelector((state) => state.customization);
    return (

        <MainContext.Provider value={{
            activeProjectState: [activeProject, setActiveProject],
            projectsState: [projects, setProjects],
            alertState: [alert, setAlert],
            displaySignModalState: displaySignModalState,
            rafflesState: [raffles, setRaffles]
        }}>

            <ThemeProvider theme={themes(customization)}>
                <NavigationScroll>
                    <CheckPubKey />
                </NavigationScroll>
            </ThemeProvider>
        </MainContext.Provider>
    );
};
export default App;

const CheckPubKey = () => {
    const { projectsState } = useContext(MainContext)
    const [projects] = projectsState
    const { publicKey } = useWallet()
    if (!publicKey) {
        return (
            <>
                <Navbar >
                    <div className='dashboard__centered-text'>
                        You have not connected your wallet
                    </div>
                </Navbar>

            </>
        )
    } else {
        if (!projects) {
            return (
                <Navbar >
                    <div className='dashboard__centered-element'>
                        <CircularProgress />
                    </div>
                </Navbar >
            )
        } else {
            if (!projects.length) {
                return (
                    <Navbar >
                        <div className='dashboard__centered-text'>
                            You do not have any project
                        </div>
                    </Navbar>
                )
            } else {
                return (
                    <Routes>
                        <Route path='/' element={<ProjectsList />} />
                        <Route path='/:id/*' element={<DashRoutes />} />
                    </Routes>
                )
            }
        }
    }
}

const ProjectsList = () => {
    const { projectsState } = useContext(MainContext)
    const [projects] = projectsState

    return (
        <>
            <Navbar>
                <div className='dashboard__centered-element'>
                    <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap' }}>
                        {projects.map((project, i) => {
                            return (
                                <>
                                    <Link to={`/dashboard/${project.id}`} key={i}>
                                        <img src={project.logo.toString()} key={project.id} alt={project.id} style={{ cursor: "pointer", height: "130px", borderRadius: "50%", border: "5px solid white", marginLeft: "20px", marginRight: "20px" }} />
                                    </Link>
                                </>
                            )
                        })}
                    </div>
                </div>
            </Navbar>

        </>
    )
}
const Navbar = ({ children }) => {
    const theme = useTheme()
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: 'white'
                }}
            >
                <Toolbar>
                    <Box
                        sx={{
                            width: 228,
                            display: 'flex',
                            [theme.breakpoints.down('md')]: {
                                width: 'auto'
                            }
                        }}
                    >
                        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                            <LogoSection />
                        </Box>
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ flexGrow: 1 }} />

                    <ConnectButton />
                </Toolbar>
            </AppBar >
            <Main theme={theme}>

                {children}
            </Main>
        </Box>
    )
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(0 - 20),
            width: `calc(100% - ${0}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${0}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${0}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${0}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));
