import React from 'react'
import headerImage from "./../../assets/img/homePage/headerImage.png"
import nameHeader from "./../../assets/img/homePage/nameHeader.png"

export default function Header() {
    return (
        <div className='header' id='header'>
            <div style={{ height: "20vh" }}></div>
            <div className='header-content'>
                <div className='header-text'>
                    <img src={nameHeader} className='header-text-img' alt='name-header'/>
                    <div className='header-text-content'>ETAKit, The ONE STOP SOLUTION for NFT projects</div>
                </div>
                <div>
                    <img src={headerImage} className='header-image' alt={"Header"} />
                </div>
            </div>
        </div>
    )
}
